import { storeToRefs } from "pinia";
import { watch, onBeforeMount } from "vue";

import { useAuthStore } from "@/entities";
import { hasToken } from "@/pages";
import { isFunction } from "@/shared";

export const useAuth = ({ onAuthenticate = null, onAuthorize = null } = {}) => {
    const authStore = useAuthStore();
    const { setAuthenticated, setAuthorized } = authStore;
    const { isAuthenticated, isAuthorized } = storeToRefs(authStore);

    watch(isAuthenticated, async () => {
        if (isAuthenticated.value) {
            if (isFunction(onAuthenticate)) await onAuthenticate();

            setAuthorized(true);

            if (isFunction(onAuthorize)) onAuthorize();
        }
    });

    onBeforeMount(() => {
        if (hasToken()) {
            setAuthenticated(true);
            // TODO: Раскоментировать и проверить
            // authStore.refreshUser();
        }
    });

    return { isAuthenticated, isAuthorized };
};

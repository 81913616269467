import { onMounted, ref, watchEffect } from "vue";
import { useTippy } from "vue-tippy";

export const useSidebarTooltips = ({
    tooltipContent,
    isTooltipInteractive,
    shouldHideTooltip,
    element,
}) => {
    const tippyInstance = ref(null);

    watchEffect(() => {
        if (!tippyInstance.value) return;

        if (shouldHideTooltip.value) tippyInstance.value.disable();
        else tippyInstance.value.enable();
    });

    onMounted(() => {
        const { tippy } = useTippy(element, {
            content: tooltipContent,
            placement: "right",
            theme: "dark",
            interactive: isTooltipInteractive,
            arrow: true,
            delay: [100, 0],
            appendTo: () => document.body,
        });

        tippyInstance.value = tippy.value;
    });
};

import {
    getDataMapper,
    mapField,
    isString,
    parseISODuration,
    isNil,
} from "@/shared";

const mapGoogleAccessTokenFromServer = getDataMapper({
    accessToken: mapField("access_token"),
    expiresIn: mapField("expires_in", Number),
    scope: mapField("scope"),
    tokenType: mapField("token_type"),
    created: mapField("created", Number),
});

export const mapSettingsFromServer = getDataMapper({
    id: mapField("id", Number),
    minRentTime: mapField(
        "min_rent_time",
        (duration) => {
            if (isNil(duration)) duration = "PT0S";

            return parseISODuration(duration);
        },
        { applyToNil: true },
    ),
    const: mapField("const"),
    shiftReportEmail: mapField("shift_report_email"),
    shouldSendReports: mapField("send_shift_report", Boolean),
    groupId: mapField("group_id", Number),
    needCalcBreaking: mapField("need_calc_breaking", Boolean),
    needCalcRentDelay: mapField("need_calc_rent_delay", Boolean),
    activeHumanBalance: mapField("active_human_balance", Number),
    activeClientVerify: mapField("active_client_verify", Number),
    currency: mapField("currency", {
        const: mapField("const"),
    }),
    currencyId: mapField("currency", (currency) => currency?.id),
    timezoneId: mapField("timezone", (timezone) => timezone?.id),
    rentOrderNumber: mapField("rent_order_number", Number),
    rentOrderFormat: mapField("rent_order_format"),
    stockOrderNumber: mapField("stock_order_number", Number),
    stockOrderFormat: mapField("stock_order_format"),
    repairOrderNumber: mapField("repair_order_number", Number),
    repairOrderFormat: mapField("repair_order_format"),
    firstEnter: mapField("first_enter", Boolean),
    hasUserToken: mapField("has_user_token", Boolean),
    isDefaultTypeLegalEntity: mapField("is_legal_entity", Boolean),
    notifyBeforeEndRent: mapField("notify_before_end_rent", Boolean),
    notifyBeforeStartRent: mapField("notify_before_start_rent", Boolean),
    notifyAfterEndRent: mapField("notify_after_end_rent", Boolean),
    shouldSendRentNotifications: mapField("notify_rent", Boolean),
    notifyChat: mapField("notify_chat", Boolean),
    shouldSendShiftNotifications: mapField("notify_shift", Boolean),
    hasModuleDelivery: mapField("has_module_delivery", Boolean),
    hasModuleRent: mapField("has_module_rent", Boolean),
    hasModuleRepair: mapField("has_module_repair", Boolean),
    hasModuleStock: mapField("has_module_stock", Boolean),
    isRentFine: mapField("is_rent_fine", Boolean),
    login: mapField("login"),
    rentHumanBalancePay: mapField("rent_human_balance_pay", Number),
    googleAccessTokenJson: mapField("google_access_token_json", (value) => {
        if (isString(value)) {
            return mapGoogleAccessTokenFromServer(JSON.parse(value));
        }

        return null;
    }),
    googleCalendarId: mapField("google_calendar_id", Number),
    amoAppId: mapField("amo_app_id", Number),
    amoSecret: mapField("amo_secret"),
    amoAccessToken: mapField("amo_access_token"),
    amoRefreshToken: mapField("amo_refresh_token"),
    amoBaseDomain: mapField("amo_base_domain"),
    myCallsKey: mapField("my_calls_key"),
    myCallsUrl: mapField("my_calls_url"),
    myCallsUsername: mapField("my_calls_username"),
    zadarmaPhone: mapField("zadarma_phone"),
    zadarmaKey: mapField("zadarma_key"),
    zadarmaSecret: mapField("zadarma_secret"),
    smsDPassword: mapField("sms_d_password"),
    smsDLogin: mapField("sms_d_login"),
    zadarmaAtc: mapField("zadarma_atc"),
    myCallsStatus: mapField("my_calls_status", Number),
    smsDiscountActiveName: mapField("sms_discount_active_name"),
});

export default {
    date: {
        type: Date,
        default: new Date(),
    },
    icon: {
        type: String,
        default: "attention",
    },
    text: {
        type: [String, Error],
        default: "",
    },
    title: {
        type: String,
        default: "",
    },
    item: {
        type: Object,
        default: null,
    },
    duration: {
        type: Number,
    },
};

export const DIRECTOR_RIGHTS = {
    ANALYTIC: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    REPORTS: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    RENT: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    CLIENT: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    INVENTORY: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    CALENDAR: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    EXPENSE: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    STOCK: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    REPAIR: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    EMPLOYEE: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    SETTINGS: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    LOG_JOURNAL: {
        canRead: true,
        canAdd: true,
        canEdit: true,
        canDelete: true,
        canExport: true,
    },
    hasAccessToPoints: true,
};

import { defineStore } from "pinia";
import { ref } from "vue";
export const usePaymentsStore = defineStore("payments", () => {
    const paymentTypes = ref([]);

    function setPaymentTypes(pt) {
        paymentTypes.value = pt;
    }

    return { paymentTypes, setPaymentTypes };
});

<script setup>
import { storeToRefs } from "pinia";
import { onMounted, ref, nextTick } from "vue";

import menuConstants from "../config/menuConstants";
import { useSidebarStore } from "../model/useSidebarStore";

import SidebarLink from "./SidebarLink.vue";

// store
const sidebarStore = useSidebarStore();
// const { fetchMenuLinks } = sidebarStore;
const { menuLinks, isCollapsed } = storeToRefs(sidebarStore);

/* onMounted(() => {
        if (menuLinks.value.length === 0)fetchMenuLinks();
}); */

// access
/* const checkAccess = (desiredConst) => {
    if (!menuLinks.value) return false;

    return menuLinks.value.some((menuLink) => {
        return menuLink.const === desiredConst;
    });
}; */

// collapse
const sidebarRef = ref(null);
const checkScrollbarExistence = () => {
    return sidebarRef.value.scrollHeight > sidebarRef.value.clientHeight;
};

const setSidebarWidthDependingOnScrollbar = async () => {
    await nextTick();

    const root = document.querySelector(":root");

    if (checkScrollbarExistence()) {
        root.style.setProperty(
            "--sidebar-collapsed-width",
            "calc(var(--sidebar-collapsed-width-const) + var(--default-scrollbar-width))",
        );
    } else {
        root.style.setProperty(
            "--sidebar-collapsed-width",
            "var(--sidebar-collapsed-width-const)",
        );
    }
};

onMounted(() => {
    setSidebarWidthDependingOnScrollbar();
});
</script>

<template>
    <aside
        v-if="menuLinks"
        class="the-sidebar"
        :class="{
            'the-sidebar--collapsed': isCollapsed,
            'the-sidebar--expanded': !isCollapsed,
        }"
        ref="sidebarRef"
    >
        <SidebarLink
            v-for="(link, key) in menuConstants"
            :key="key"
            :link-meta="link"
            :has-access="true"
            :is-collapsed="isCollapsed"
            @toggle-children="setSidebarWidthDependingOnScrollbar"
        />
    </aside>
</template>

<style scoped lang="scss">
.the-sidebar {
    --sidebar-link-icon-width: 16px;
    --sidebar-link-icon-height: 14px;
    position: fixed;
    top: var(--header-height);
    left: 0;

    z-index: var(--zi-sidebar);

    width: var(--sidebar-width);
    height: calc(100vh - var(--header-height));
    overflow: hidden auto;

    background-color: var(--sidebar-background);

    transition: all var(--sidebar-collapse-transition-duration);

    @media (max-width: 576px) {
        transform: translateX(-100%);
    }

    &--expanded {
        @media (max-width: 576px) {
            transform: translateX(0);
        }
    }
}
</style>

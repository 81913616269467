import {
    getDataMapper,
    mapField,
    mapContactToServer,
    formatISO,
} from "@/shared";

export const mapDirectorToServer = getDataMapper({
    id: mapField("id", Number),
    human: mapField("human", {
        id: mapField("id", Number),
        name: mapField("name", String),
        surname: mapField("surname", String),
        patro: mapField("patro", String),
        user: mapField("user", {
            id: mapField("id", Number),
            login: mapField("login", String),
            password: mapField("password", String),
        }),
    }),
    hashTag: mapField("hashTag", String),
    contacts: mapField("contacts", mapContactToServer),
    company: mapField("company", {
        title: mapField("title", String),
    }),
    sphere_id: mapField("sphereId", Number),
    appointment_id: mapField("appointmentId", Number),
    manager_id: mapField("managerId", Number),
    comment: mapField("comment", String),
    new_tariff_id: mapField("newTariffId", Number),
    period_id: mapField("periodId", Number),
    tariff_time_start: mapField("tariffTimeStart", formatISO),
    tariff_time_end: mapField("tariffTimeEnd", formatISO),
    limit_clients: mapField("limitClients", Number),
    limit_employees: mapField("limitEmployees", Number),
    limit_inventories: mapField("limitInventories", Number),
    limit_points: mapField("limitPoints", String),
    has_module_stock: mapField("hasModuleStock", Boolean),
    has_module_repair: mapField("hasModuleRepair", Boolean),
    has_module_delivery: mapField("hasModuleDelivery", Boolean),
    has_user_token: mapField("hasUserToken", Boolean),
    period_spec: mapField("periodSpec"),
    is_new_tariff: mapField("isNewTariff", Boolean),
});

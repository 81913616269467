import { makeHttpRequest } from "@/shared";

import { mapAuthDataFromServer } from "./mappers/mapAuthDataFromServer";
import { mapAuthDataToServer } from "./mappers/mapAuthDataToServer";

export const getAuthToken = async (authData) => {
    const response = (
        await makeHttpRequest({
            url: "/login",
            method: "POST",
            data: mapAuthDataToServer(authData),
        })
    ).data;

    return mapAuthDataFromServer(response.data);
};

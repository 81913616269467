export default {
    variant: {
        type: String,
        validator(value) {
            return [
                "primary",
                "default", // он же светло-серый цвет
                "gray",
                "red",
                "secondary",
                "white",
                "invisible",
                "none",
            ].includes(value);
        },
        default: "default",
    },
    type: {
        type: String,
        validator(value) {
            return ["rounded", "circle", "square"].includes(value);
        },
        default: "rounded",
    },
    size: {
        type: String,
        validator(value) {
            return ["sm", "md", "lg"].includes(value);
        },
        default: "md",
    },
    hasShadow: {
        type: Boolean,
        default: false,
    },
    hasOutline: {
        type: Boolean,
        default: false,
    },
    isActive: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
};

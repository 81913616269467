import { getDataMapper, mapField } from "@/shared";

export const mapDirectorTariffFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    cost: mapField("cost"),
    period: mapField("period"),
    timeStart: mapField("time_start"),
    timeEnd: mapField("time_end"),
    limitClients: mapField("limit_clients"),
    limitEmployees: mapField("limit_employees"),
    limitInventories: mapField("limit_inventories"),
    limitPoints: mapField("limit_points"),
});

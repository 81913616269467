import { createPinia } from "pinia";
import { createApp } from "vue";

import { withRouter } from "./providers/withRouter";
import App from "./ui/App.vue";

import "./styles/main.scss";

const app = createApp(App);

app.use(createPinia());
withRouter(app);

export const initApplication = async () => {
    app.mount("#app");
};

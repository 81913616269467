import { makeHttpRequest } from "@/shared";

import { mapRightsFromServer } from "./mappers/mapRightsFromServer.js";

export const getRights = async function (employeeId) {
    const { data } = await makeHttpRequest({
        method: "GET",
        url: `human/employee/${employeeId}/menu-rights`,
    });

    return mapRightsFromServer(data.data);
};

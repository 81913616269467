import { getDataMapper, mapField, parseISO } from "../../../lib";
export const mapHumanFromServer = getDataMapper({
    id: mapField("id", Number),
    userId: mapField("user_id", Number),
    guid: mapField("guid", String),
    number: mapField("number", Number),
    surname: mapField("surname"),
    name: mapField("name"),
    patro: mapField("patro"),
    fio: mapField("fio"),
    shortFio: mapField("short_fio"),
    avatarFio: mapField("avatar_fio"),
    avatar: mapField("avatar"),
    birthday: mapField("birthday", parseISO),
    isMale: mapField("male", Boolean),
    isAdmin: mapField("is_admin", Boolean),
    isDirector: mapField("is_director", Boolean),
    isEmployee: mapField("is_employee", Boolean),
    isClient: mapField("is_client", Boolean),
    passportSerial: mapField("passport_serial"),
    passportNumber: mapField("passport_number"),
    passportTake: mapField("passport_take"),
    passportCode: mapField("passport_code"),
    passportTakeDate: mapField("passport_take_date", parseISO),
    vatin: mapField("vatin"),
    smsFlag: mapField("sms_flag", Boolean),
});

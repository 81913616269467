/* eslint-disable node/no-unsupported-features/es-syntax */
import { GENERAL_ROUTE_NAMES } from "@/shared";

export const GENERAL_ROUTES = [
    {
        path: "/:pathMatch(.*)*",
        name: GENERAL_ROUTE_NAMES.NOT_FOUND,
        meta: {
            title: "Страница не найдена",
        },
        component: () => import("../ui/NotFound.vue"),
    },
    {
        path: "/",
        name: GENERAL_ROUTE_NAMES.DEFAULT,
        redirect: { name: GENERAL_ROUTE_NAMES.DASHBOARD },
    },
];

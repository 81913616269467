import { createRouter, createWebHistory } from "vue-router";

import { ROUTES, isAuthRoute, hasToken } from "@/pages";
import { AUTHENTICATION_ROUTE_NAMES, GENERAL_ROUTE_NAMES } from "@/shared";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: ROUTES,
});

router.beforeEach((to) => {
    const isAuthenticated = hasToken();
    const isCurrentRouteAuth = isAuthRoute(to.name);

    if (!isAuthenticated && !isCurrentRouteAuth) {
        return { name: AUTHENTICATION_ROUTE_NAMES.LOGIN };
    }

    if (isAuthenticated && isCurrentRouteAuth) {
        return { name: GENERAL_ROUTE_NAMES.DEFAULT };
    }
});

export const withRouter = (application) => {
    application.use(router);
};

export { router };

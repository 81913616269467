import {
    // icons
    IconChartArea,
    IconUsers,
    IconMoneyBills,

    // route names
    GENERAL_ROUTE_NAMES,
    DIRECTOR_ROUTE_NAMES,
    PAYMENTS_ROUTE_NAMES,
} from "@/shared";

export default {
    MENU_A_DASHBOARD: {
        icon: IconChartArea,
        title: "Аналитика",
        to: { name: GENERAL_ROUTE_NAMES.DASHBOARD },
        activeRoutes: { DASHBOARD: GENERAL_ROUTE_NAMES.DASHBOARD },
    },
    MENU_A_DIRECTORS: {
        icon: IconUsers,
        title: "Директора",
        to: { name: DIRECTOR_ROUTE_NAMES.LIST },
        activeRoutes: DIRECTOR_ROUTE_NAMES,
    },
    MENU_A_PAYMENTS: {
        icon: IconMoneyBills,
        title: "Платежи",
        to: { name: PAYMENTS_ROUTE_NAMES.LIST },
        activeRoutes: PAYMENTS_ROUTE_NAMES,
    },
};

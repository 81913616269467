import { AUTHENTICATION_ROUTES, isAuthRoute } from "./authentication";
import { DASHBOARD_ROUTES } from "./dashboard";
import { DIRECTOR_ROUTES } from "./director";
import { GENERAL_ROUTES } from "./general";
import { PAYMENTS_ROUTES } from "./payments";

export const ROUTES = [
    ...AUTHENTICATION_ROUTES,
    ...DASHBOARD_ROUTES,
    ...DIRECTOR_ROUTES,
    ...GENERAL_ROUTES,
    ...PAYMENTS_ROUTES,
];

export { isAuthRoute };

import { getDataMapper, mapField } from "@/shared";

function TariffsData() {
    this.id = mapField("id", Number);
    this.title = mapField("title", String);
    this.usersCount = mapField("users_count", Number);
    this.children = mapField("children", this);
}

export const mapDashboardDataFromServer = getDataMapper({
    payments: mapField("payments", {
        averagePayment: mapField("average_payment", Number),
        totalPayments: mapField("total_payments", Number),
        totalSum: mapField("total_sum", Number),
    }),
    managerCalls: mapField("manager_calls", {
        id: mapField("id", Number),
        name: mapField("name", String),
        callsCount: mapField("calls_count", Number),
        statuses: mapField("statuses", {
            id: mapField("id", Number),
            title: mapField("title", String),
            const: mapField("const", String),
            callCount: mapField("call_count", Number),
        }),
    }),
    stats: mapField("stats", {
        NU: mapField("new_directors_count", Number),
        CR: mapField("conversion_rate", Number),
        PU: mapField("directors_with_active_tariffs_count", Number),
    }),
    tariffsData: mapField("tariffs_data", new TariffsData()),
    chartData: mapField("chart_data", {
        activeTariffsChart: mapField("active_tariffs_for_chart", {
            value: mapField("active_tariffs", Number),
            date: mapField("date", String),
        }),
        paymentsChart: mapField("payments_for_chart", {
            value: mapField("sum", Number),
            date: mapField("date", String),
            count: mapField("count", Number),
        }),
        registrationsChart: mapField("registrations_for_chart", {
            value: mapField("count", Number),
            date: mapField("date", String),
        }),
    }),
    funnelData: mapField("funnel_data", {
        id: mapField("id", Number),
        title: mapField("title", String),
        const: mapField("const", String),
        usersCount: mapField("users_count", Number),
        percentage: mapField("percentage", Number),
        color: mapField("color", String),
        textColor: mapField("text_color", String),
    }),
    spheresData: mapField("spheres_data", {
        id: mapField("id", Number),
        title: mapField("title", String),
        payingDirectorsCount: mapField("paying_directors_count", Number),
        registeredDirectorsCount: mapField(
            "registered_directors_count",
            Number,
        ),
    }),
});

<script setup>
import { RIHLogo, useAuthStore } from "@/entities";
import {
    BaseHeaderTemplate,
    BaseButton,
    IconBars,
    IconArrowRightFromBracket,
    vTooltip,
} from "@/shared";

defineProps({
    isSidebarCollapsed: {
        type: Boolean,
        default: false,
    },
});
defineEmits(["changeSidebarMode"]);

const authStore = useAuthStore();

const handleExit = async function () {
    await authStore.logout();

    location.replace(`https://${import.meta.env.VITE_APP_LK}`);
};
</script>

<template>
    <BaseHeaderTemplate
        class="the-header"
        :is-sidebar-collapsed="isSidebarCollapsed"
    >
        <template #logo>
            <RIHLogo :is-collapsed="isSidebarCollapsed" />
        </template>
        <template #content>
            <BaseButton
                class="the-header__collapse-btn the-header__btn"
                variant="primary"
                type="square"
                @click="$emit('changeSidebarMode')"
            >
                <IconBars />
            </BaseButton>
            <BaseButton
                class="the-header__btn"
                variant="primary"
                type="circle"
                v-tooltip.bottom="'Выход'"
                @click="handleExit"
            >
                <IconArrowRightFromBracket class="the-header__icon" />
            </BaseButton>
        </template>
    </BaseHeaderTemplate>
</template>

<style lang="scss" scoped>
.the-header {
    &__collapse-btn {
        --button-border-color: var(--button-color);
        --button-border-color-hover: var(--button-color-hover);
        --button-width: var(--header-height);
        --button-height: var(--header-height);

        flex-shrink: 0;

        margin-right: auto;
    }

    &__fast-create-btn {
        margin-right: 10px;
        margin-left: 10px;
    }

    &__icon {
        &--lifebuoy {
            width: 20px !important;
            height: auto;
        }
    }

    &__btn {
        flex-shrink: 0;

        &:active {
            svg {
                color: var(--c-green-10) !important;
            }
        }
    }
}
</style>

import { getDataMapper, mapField } from "@/shared";

export const mapRightFromServer = getDataMapper({
    canRead: mapField("read", Boolean),
    canAdd: mapField("create", Boolean),
    canEdit: mapField("update", Boolean),
    canDelete: mapField("delete", Boolean),
    canExport: mapField("export", Boolean),
});

export const mapRightsFromServer = getDataMapper({
    ANALYTIC: mapField("ANALYTIC", mapRightFromServer),
    REPORTS: mapField("REPORTS", mapRightFromServer),
    RENT: mapField("RENT", mapRightFromServer),
    CLIENT: mapField("CLIENT", mapRightFromServer),
    INVENTORY: mapField("INVENTORY", mapRightFromServer),
    CALENDAR: mapField("CALENDAR", mapRightFromServer),
    EXPENSE: mapField("EXPENSE", mapRightFromServer),
    STOCK: mapField("STOCK", mapRightFromServer),
    REPAIR: mapField("REPAIR", mapRightFromServer),
    EMPLOYEE: mapField("EMPLOYEE", mapRightFromServer),
    SETTINGS: mapField("SETTINGS", mapRightFromServer),
    LOG_JOURNAL: mapField("LOG_JOURNAL", mapRightFromServer),
    hasAccessToPoints: mapField("access_to_points", Boolean),
});

import {
    getDataMapper,
    mapArray,
    mapContactFromServer,
    mapField,
    mapHumanFromServer,
    parseISO,
} from "@/shared";

export const mapDirectorFromServer = getDataMapper({
    id: mapField("id", Number),
    company: mapField("company"),
    registerDate: mapField("register_date"),
    hashTag: mapField("hash_tag"),
    tariff: mapField(
        "tariff_active",
        getDataMapper({
            id: mapField("id", Number),
            title: mapField("title"),
            periodId: mapField("period_id"),
            tariffPlanId: mapField("tariff_plan_id", Number),
            timeEnd: mapField("time_end"),
            periodSpec: mapField("period_spec"),
        }),
    ),
    timeDisactive: mapField("time_disactive"),
    human: mapField("human", mapHumanFromServer),
    funnel: mapField(
        "funnel",
        getDataMapper({
            id: mapField("id", Number),
            title: mapField("title"),
            const: mapField("const"),
            color: mapField("color"),
            textColor: mapField("text_color"),
        }),
    ),
    contacts: mapField("contacts", mapContactFromServer),
    status: mapField(
        "status",
        getDataMapper({
            id: mapField("id", Number),
            title: mapField("title"),
            const: mapField("const"),
            color: mapField("color"),
        }),
    ),
    manager: mapField("manager"),
    sphere: mapField("sphere"),
    lastCall: mapField(
        "last_call",
        getDataMapper({
            id: mapField("id", Number),
            directorId: mapField("director_id", Number),
            manager: mapField("manager"),
            callDate: mapField("call_date", parseISO),
            comment: mapField("comment"),
            status: mapField("status"),
        }),
    ),
    lastVisit: mapField("last_visit"),
    emailConfirmed: mapField("email_confirmed", Boolean),
    login: mapField("login"),
    comment: mapField("comment"),
    autoPayment: mapField("auto_payment"),
    appointment: mapField("appointment"),
    settings: mapField("settings", {
        hasModuleRepair: mapField("has_module_repair", Boolean),
        hasModuleStock: mapField("has_module_stock", Boolean),
        hasModuleDelivery: mapField("has_module_delivery", Boolean),
        hasUserToken: mapField("has_user_token", Boolean),
    }),
    user: mapField("user"),
    userId: mapField("user_id"),
    registeredThrough: mapField("registered_through"),
});

export const mapDirectorsFromServer = mapArray(mapDirectorFromServer);

<script setup>
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";

import { TheRouting, isAuthRoute } from "@/pages";
import { useAuth, setResponseInterceptor, initWorkspace } from "@/processes";
import {
    BaseLoader,
    BaseNotifications,
    setScrollBarToCssVariables,
    GENERAL_ROUTE_NAMES,
} from "@/shared";
import { TheSidebar, TheHeader, useSidebarStore } from "@/widgets";

// TODO: исправить eslint так, чтобы это не была ошибка
// eslint-disable-next-line boundaries/element-types
import { useDynamicTitle } from "../lib/useDynamicTitle";

// interceptors
setResponseInterceptor();

// title with route meta
useDynamicTitle();

// auth
const router = useRouter();
const route = useRoute();
const onAuthenticate = async () => {
    await initWorkspace();

    const isCurrentRouteAuth = isAuthRoute(route.name);

    if (isCurrentRouteAuth)
        await router.push({ name: GENERAL_ROUTE_NAMES.DEFAULT });
};

const { isAuthorized, isAuthenticated } = useAuth({
    onAuthenticate,
});

// already authenticated but not yet authorized
const isAuthorizing = computed(
    () => isAuthenticated.value && !isAuthorized.value,
);

// sidebar controlling
const { toggleCollapsedMode } = useSidebarStore();
const { isCollapsed } = storeToRefs(useSidebarStore());

// set scrollbar width
setScrollBarToCssVariables("--default-scrollbar-width");
</script>

<template>
    <template v-if="isAuthorized">
        <TheHeader
            @change-sidebar-mode="toggleCollapsedMode"
            :is-sidebar-collapsed="isCollapsed"
        />
        <TheSidebar />
        <main class="app__authorized">
            <TheRouting />
        </main>
    </template>
    <template v-else>
        <main class="app__unauthorized">
            <BaseLoader v-if="isAuthorizing" />
            <TheRouting v-else />
        </main>
    </template>
    <BaseNotifications
        position="top right"
        :x-offset="12"
        :y-offset="75"
        :default-duration="4500"
        ignore-duplicates
    />
</template>

<style scoped lang="scss">
.app {
    &__authorized {
        width: calc(100vw - var(--sidebar-width));
        padding: var(--main-paddings);

        margin-top: var(--header-height);
        margin-left: var(--sidebar-width);

        transition: all var(--sidebar-collapse-transition-duration);

        @media (max-width: 576px) {
            width: 100vw;
            margin-left: 0;
        }
    }

    &__unauthorized {
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
}
</style>

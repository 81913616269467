<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import { useUserStore } from "@/entities";
import { IconAngleLeft, isObject, isNil, isArray } from "@/shared";

import { useSidebarTooltips } from "../lib/useSidebarTooltips";
import { useSidebarStore } from "../model/useSidebarStore";

// config
const props = defineProps({
    linkMeta: Object,
    hasAccess: {
        type: Boolean,
        default: false,
    },
    isCollapsed: Boolean,
});

const emit = defineEmits(["toggleChildren"]);

const route = useRoute();

const sidebarStore = useSidebarStore();
const userStore = useUserStore();

// validate
const shouldDisplayLink = computed(() => {
    return (
        props.linkMeta &&
        (props.hasAccess ||
            (userStore.isDirector && props.linkMeta.shouldDisplayWithoutAccess))
    );
});

// active
const isActive = computed(() => {
    if (isNil(props.linkMeta.activeRoutes)) return false;

    if (isArray(props.linkMeta.activeRoutes)) {
        const allLinks = props.linkMeta.activeRoutes.reduce((links, link) => {
            links.push(...Object.values(link));

            return links;
        }, []);

        return allLinks.some((routeName) => routeName === route.name);
    }

    return Object.values(props.linkMeta.activeRoutes).some(
        (routeName) => routeName === route.name,
    );
});

// children
const childrenOpened = ref(false);

const hasChildren = computed(() => {
    return (
        isObject(props.linkMeta.children) &&
        Object.entries(props.linkMeta.children).length > 0
    );
});

const toggleChildren = () => {
    childrenOpened.value = props.hasAccess && !childrenOpened.value;
    emit("toggleChildren");
};

// tooltips
const linkRef = ref(null);

const tooltipContent = props.hasAccess
    ? props.linkMeta.title
    : (props.linkMeta.tooltipContent ?? props.linkMeta.title);

const isTooltipInteractive =
    !props.hasAccess && props.linkMeta.shouldDisplayWithoutAccess;

const shouldHideTooltip = computed(() => {
    return (
        !props.isCollapsed &&
        (props.hasAccess || !props.linkMeta.shouldDisplayWithoutAccess)
    );
});

useSidebarTooltips({
    tooltipContent,
    isTooltipInteractive,
    shouldHideTooltip,
    element: linkRef,
});

const handleNavigate = (e, navigate) => {
    const isMobile = window.innerWidth <= 576;

    if (isMobile) {
        sidebarStore.setCollapsedMode(true);
    }

    navigate(e);
};
</script>

<template>
    <RouterLink
        v-if="shouldDisplayLink"
        :to="linkMeta.to ?? {}"
        custom
        v-slot="{ href, navigate }"
    >
        <div
            v-if="hasChildren || !hasAccess"
            class="sidebar-link"
            :class="{
                'sidebar-link--disabled': !hasAccess,
                'sidebar-link--collapsed': isCollapsed,
                'sidebar-link--active': isActive,
            }"
            @click="toggleChildren"
            v-bind="$attrs"
            ref="linkRef"
        >
            <component
                class="sidebar-link__icon"
                v-if="linkMeta.icon"
                :is="linkMeta.icon"
            />
            {{ linkMeta.title }}
            <IconAngleLeft
                class="sidebar-link__angle"
                :class="{ 'sidebar-link__angle--rotate-down': childrenOpened }"
                v-if="hasChildren"
            />
        </div>
        <a
            v-else
            class="sidebar-link"
            :class="{
                'sidebar-link--collapsed': isCollapsed,
                'sidebar-link--active': isActive,
            }"
            :href="href"
            @click="handleNavigate($event, navigate)"
            v-bind="$attrs"
            ref="linkRef"
        >
            <component
                class="sidebar-link__icon"
                v-if="linkMeta.icon"
                :is="linkMeta.icon"
            />
            {{ linkMeta.title }}
        </a>
    </RouterLink>

    <div class="sidebar-link__children" v-if="hasChildren && childrenOpened">
        <SidebarLink
            class="sidebar-link__child"
            v-for="(childLink, key) in linkMeta.children"
            :key="key"
            :link-meta="childLink"
            :is-collapsed="isCollapsed"
            has-access
        />
    </div>
</template>

<style scoped lang="scss">
$sidebar-link: ".sidebar-link";

#{$sidebar-link} {
    display: flex;

    align-items: center;

    padding: 14px;

    font-size: 14px;
    font-weight: 600;

    color: var(--c-neutral-30);
    text-decoration: none;
    white-space: nowrap;

    border-left: 0 solid var(--c-primary);

    transition: all var(--sidebar-collapse-transition-duration);

    &:hover {
        color: var(--c-neutral-10);

        cursor: pointer;

        background-color: var(--sidebar-background-hover);
    }

    &__icon {
        flex-shrink: 0;

        width: var(--sidebar-link-icon-width);
        height: var(--sidebar-link-icon-height);
        margin-right: 8px;

        transition: margin-right var(--sidebar-collapse-transition-duration);
    }

    &__angle {
        width: auto;
        height: 14px;
        margin-left: auto;

        transition: transform 0.2s;

        &--rotate-down {
            transform: rotateZ(-90deg);
        }
    }

    &__children {
        background-color: var(--sidebar-background-hover);

        transition: all var(--sidebar-collapse-transition-duration);
    }

    &__child {
        padding: 7px 7px 7px 29px;

        transition: all var(--sidebar-collapse-transition-duration);
    }

    &--collapsed {
        @media (min-width: 577px) {
            padding: 14px
                calc(
                    (
                            var(--sidebar-collapsed-width-const) - var(
                                    --sidebar-link-icon-width
                                )
                        ) / 2
                );

            #{$sidebar-link}__icon {
                margin-right: 40px;
            }

            & + #{$sidebar-link}__children {
                padding-left: 0;
            }

            #{$sidebar-link}__child {
                padding: 7px
                    calc(
                        (
                                var(--sidebar-collapsed-width-const) - var(
                                        --sidebar-link-icon-width
                                    )
                            ) / 2
                    );
            }
        }
    }

    &--disabled {
        color: var(--c-neutral-50) !important;

        cursor: help !important;

        background-color: var(--sidebar-background-disabled) !important;
    }

    &--active {
        color: var(--c-neutral-10);

        background-color: var(--sidebar-background-hover);
        border-left-width: 4px;
    }
}
</style>

/* eslint-disable node/no-unsupported-features/es-syntax */
import { AUTHENTICATION_ROUTE_NAMES } from "@/shared";
export const AUTHENTICATION_ROUTES = [
    {
        name: AUTHENTICATION_ROUTE_NAMES.PAGE,
        path: "/auth",
        component: () => import("../ui/AuthenticationPage.vue"),
        children: [
            {
                name: AUTHENTICATION_ROUTE_NAMES.LOGIN,
                path: "login",
                meta: {
                    title: "Авторизация",
                },
                component: () => import("../ui/LoginPage.vue"),
            },
        ],
    },
];

import { makeHttpRequest } from "@/shared";

import { mapAuthDataFromServer } from "./mappers/mapAuthDataFromServer";

export const getRefreshToken = async () => {
    const response = (
        await makeHttpRequest({
            url: "/token/refresh",
            method: "POST",
        })
    ).data;

    return mapAuthDataFromServer(response);
};

/* eslint-disable node/no-unsupported-features/es-syntax */
import { DIRECTOR_ROUTE_NAMES } from "@/shared";

export const DIRECTOR_ROUTES = [
    {
        name: DIRECTOR_ROUTE_NAMES.LIST,
        path: "/director",
        meta: {
            title: "Список директоров",
        },
        component: () => import("../ui/DirectorList.vue"),
    },
    {
        name: DIRECTOR_ROUTE_NAMES.PROFILE,
        path: "/director/:id",
        meta: {
            title: "Директор",
        },
        component: () => import("../ui/DirectorProfile.vue"),
        props: true,
    },
];

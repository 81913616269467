import { makeHttpRequest } from "@/shared";

export async function logoutUser(comment) {
    const response = (
        await makeHttpRequest({
            url: "/logout",
            method: "GET",
            params: { comment },
        })
    ).data;

    return response;
}

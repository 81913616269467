import { makeHttpRequest } from "@/shared";

export const getWorkspaceSettings = async function () {
    const { data } = await makeHttpRequest({
        url: "/workspace/init",
        method: "GET",
    });

    return data.data;
};

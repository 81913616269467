import { parseISO, setCookie } from "@/shared";

export const setAuthToken = (token, expires) => {
    let domain = window.location.hostname;

    if (domain.includes("lk") || domain.includes("admin")) {
        domain = domain.split(".").slice(-2).join(".");
    }

    setCookie("jwt", token, {
        expires: parseISO(expires).toUTCString(),
        domain,
    });
};

import { deleteCookie } from "@/shared";

export const deleteAuthToken = function () {
    let domain = window.location.hostname;

    if (domain.includes("lk") || domain.includes("admin")) {
        domain = domain.split(".").slice(-2).join(".");
    }

    deleteCookie("jwt", { domain });
};

import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { updateSettings } from "../api/updateSettings";

export const useSettingsStore = defineStore("SettingsStore", () => {
    const settings = ref(null);
    const token = ref(null);

    const setSettings = function (s) {
        settings.value = s;
    };

    const setToken = function (t) {
        token.value = t;
    };

    const saveChanges = async function () {
        await updateSettings(settings.value);
    };

    const currencyConst = computed(() => {
        return settings.value?.currency?.const;
    });

    return {
        settings,
        token,
        setToken,
        setSettings,
        saveChanges,
        currencyConst,
    };
});

import { makeHttpRequest } from "@/shared";

import { mapSettingsFromServer } from "./mappers/mapSettingsFromServer";
import { mapSettingsToServer } from "./mappers/mapSettingsToServer";

export const updateSettings = async (settings) => {
    const response = (
        await makeHttpRequest({
            url: `/settings`,
            method: "PUT",
            data: mapSettingsToServer(settings),
        })
    ).data;

    return mapSettingsFromServer(response.data);
};

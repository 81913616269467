import { getDataMapper, mapArray, mapField } from "@/shared";

export const mapPaymentFromServer = getDataMapper({
    id: mapField("id", Number),
    directorId: mapField("director_id", Number),
    directorFio: mapField("director_fio", String),
    directorEmail: mapField("director_email", String),
    datePay: mapField("date_pay"),
    tariffName: mapField("tariff_name"),
    tariffPeriod: mapField("tariff_period"),
    paymentIsAuto: mapField("payment_is_auto", Boolean),
    paymentSum: mapField("payment_sum"),
});

export const mapPaymentsFromServer = mapArray(mapPaymentFromServer);

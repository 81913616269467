import { makeHttpRequest } from "@/shared";

export const getMenuLinks = async () => {
    return (
        await makeHttpRequest({
            url: "/my/settings/menu",
            method: "GET",
        })
    ).data.data;
};

/* eslint-disable node/no-unsupported-features/es-syntax */
import { GENERAL_ROUTE_NAMES } from "@/shared";
export const DASHBOARD_ROUTES = [
    {
        name: GENERAL_ROUTE_NAMES.DASHBOARD,
        path: "/dashboard",
        meta: {
            title: "Аналитика",
        },
        component: () => import("../ui/DashboardApp.vue"),
    },
];

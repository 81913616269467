<script setup>
import { computed } from "vue";

import importedProps from "./config/props";

const props = defineProps({ ...importedProps });

defineEmits(["click"]);

const buttonClasses = computed(() => {
    const bntVariant = `base-btn--${props.variant}`;
    const bntType = `base-btn--${props.type}`;
    const bntSize = `base-btn--${props.size}`;
    const btnShadow = `base-btn--${props.hasShadow && "shadow"}`;
    const btnOutline = `base-btn--${props.hasOutline && "outline"}`;
    const btnActive = `base-btn--${props.isActive && "active"}`;

    const arrayClasses = ["base-btn"];

    props.variant && arrayClasses.push(bntVariant);
    props.type && arrayClasses.push(bntType);
    props.size && arrayClasses.push(bntSize);
    props.hasShadow && arrayClasses.push(btnShadow);
    props.hasOutline && arrayClasses.push(btnOutline);
    props.isActive && arrayClasses.push(btnActive);

    return arrayClasses;
});
</script>

<template>
    <button
        type="button"
        :class="buttonClasses"
        @click="$emit('click', $event)"
        :title="placeholder"
        :disabled="disabled"
    >
        <slot></slot>
    </button>
</template>

<style lang="scss" scoped>
.base-btn {
    display: flex;

    align-items: center;
    justify-content: center;

    min-height: var(--button-min-height);
    padding: var(--button-padding);

    font-size: var(--button-font-size);

    font-weight: 400;
    line-height: var(--default-line-height);

    color: var(--button-text-color);
    text-align: center;
    white-space: nowrap;

    cursor: pointer;

    user-select: none;

    background-color: var(--button-color);

    border: 1px solid var(--button-border-color);
    border-radius: var(--button-border-radius);

    transition: all 0.15s ease-in-out;

    ::v-deep(svg) {
        color: var(--button-text-color);

        transition: all 0.15s ease-in-out;
    }

    &:hover {
        background-color: var(--button-color-hover);
        border-color: var(--button-border-color-hover);
    }

    &:active {
        color: var(--button-text-color-active);

        background-color: var(--button-color-active);
        border-color: var(--button-border-color-active);

        ::v-deep(svg) {
            color: var(--button-text-color-active);

            transition: all 0.15s ease-in-out;
        }
    }

    &--active {
        cursor: default;
    }

    &--primary {
        --button-color: var(--c-green-50);
        --button-color-outline: var(--c-green-50);
        --button-text-color: var(--c-neutral-10);
        --button-border-color: var(--c-green-50);
        --button-color-hover: var(--c-green-60);
        --button-border-color-hover: var(--c-green-60);
        --button-text-color-active: var(--button-border-color);
        --button-border-color-active: var(--button-color-hover);
    }

    &--secondary {
        --button-color: var(--c-orange-50);
        --button-color-outline: var(--c-orange-50);
        --button-text-color: var(--c-neutral-10);
        --button-border-color: var(--c-orange-50);
        --button-color-hover: var(--c-orange-60);
        --button-border-color-hover: var(--c-orange-60);
        --button-text-color-active: var(--button-border-color);
        --button-border-color-active: var(--button-color-hover);
    }

    &--red {
        --button-color: var(--c-red-50);
        --button-color-outline: var(--c-red-50);
        --button-text-color: var(--c-neutral-10);
        --button-border-color: var(--c-red-50);
        --button-color-hover: var(--c-red-60);
        --button-border-color-hover: var(--c-red-60);
        --button-text-color-active: var(--button-border-color);
        --button-border-color-active: var(--button-color-hover);
    }

    &--gray {
        --button-color: var(--c-neutral-60);
        --button-color-outline: var(--c-neutral-60);
        --button-text-color: var(--c-neutral-10);
        --button-border-color: var(--c-neutral-60);
        --button-color-hover: var(--c-neutral-70);
        --button-border-color-hover: var(--c-neutral-70);
        --button-text-color-active: var(--button-border-color);
        --button-border-color-active: var(--button-color-hover);
    }

    &--default {
        --button-color: var(--c-neutral-20);
        --button-color-outline: var(--c-neutral-20);
        --button-text-color: var(--c-neutral-70);
        --button-border-color: var(--c-neutral-20);
        --button-color-hover: var(--c-neutral-30);
        --button-border-color-hover: var(--c-neutral-30);
        --button-text-color-active: var(--c-neutral-50);
        --button-border-color-active: var(--button-color-hover);
    }

    &--white {
        --button-color: var(--c-neutral-10);
        --button-color-outline: var(--c-neutral-17);
        --button-text-color: var(--c-neutral-70);
        --button-border-color: var(--c-neutral-17);
        --button-color-hover: var(--c-neutral-12);
        --button-color-active: var(--c-neutral-17);
        --button-border-color-hover: var(--c-neutral-20);
        --button-text-color-active: var(--c-primary);
        --button-border-color-active: var(--c-primary);

        &.base-btn--active {
            --button-border-color: var(--c-primary);
            --button-text-color: var(--c-primary);
            --button-color: var(--c-neutral-12);
            --button-color-active: var(--c-neutral-12);
            --button-border-color-hover: var(--c-primary);
        }
    }

    &--invisible {
        --button-color-hover: var(--c-neutral-17);
        --button-color-active: var(--c-neutral-17);
        --button-border-color-hover: var(--c-neutral-20);
        --button-text-color-active: var(--c-primary);
        --button-border-color-active: var(--c-primary);

        &.base-btn--active {
            --button-border-color: var(--c-primary);
            --button-text-color: var(--c-primary);
            --button-color: var(--c-neutral-17);
            --button-color-active: var(--c-neutral-17);
            --button-border-color-hover: var(--c-primary);
        }
    }

    &--outline {
        --button-color: transparent;
        --button-text-color: var(--c-neutral-40);
        --button-border-color: var(--c-neutral-20);
        --button-color-hover: transparent;
        --button-border-color-hover: var(--button-color-outline);
        --button-border-color-active: var(--button-color-outline);
    }

    &--rounded {
        --button-border-radius: var(--default-border-radius);
    }

    &--xs {
        --button-min-height: 22px;
        --button-padding: 2px 10px;
        --button-font-size: 12px;

        ::v-deep(svg) {
            width: 10px;
            height: 10px;
        }
    }

    &--sm {
        --button-min-height: 28px;
        --button-padding: 3px 14px;
        --button-font-size: 14px;

        ::v-deep(svg) {
            width: 12px;
            height: 12px;
        }
    }

    &--md {
        --button-font-size: 16px;
        --button-min-height: 40px;
        --button-padding: 5px 24px;
    }

    &--lg {
        --button-font-size: 18px;
        --button-min-height: 50px;
        --button-padding: 10px 30px;
        --button-border-radius: 30px;
    }

    &--square {
        width: var(--button-width);
        height: var(--button-height);

        --button-padding: 0;
        --button-border-radius: 0;
    }

    &--circle {
        width: var(--button-width);
        height: var(--button-height);

        --button-padding: 0;
        --button-border-radius: 50%;

        &.base-btn {
            &--xs {
                --button-width: 22px;
                --button-height: 22px;
            }

            &--sm {
                --button-width: 28px;
                --button-height: 28px;
            }

            &--md {
                --button-width: 40px;
                --button-height: 40px;
            }

            &--lg {
                --button-width: 50px;
                --button-height: 50px;
            }
        }
    }

    &--shadow {
        box-shadow: 0 4px 4px 0 rgb(var(--c-rgb-neutral-90) / 40%);

        &:hover,
        &.base-btn--active {
            box-shadow: 0 5px 4px 0 rgb(var(--c-rgb-neutral-90) / 30%);
        }

        &:active {
            box-shadow: 0 3px 4px 0 rgb(var(--c-rgb-neutral-90) / 50%);
        }
    }

    &:disabled,
    &:disabled .base-btn:hover {
        pointer-events: none;
        cursor: default;

        opacity: 0.5;
    }
}
</style>

export default {
    position: {
        type: String,
        default: "top right",
        validator(value) {
            return (
                [
                    "top right",
                    "top left",
                    "bottom right",
                    "bottom left",
                ].indexOf(value) !== -1
            );
        },
    },
    xOffset: {
        type: Number,
        default: 0,
    },
    yOffset: {
        type: Number,
        default: 0,
    },
    width: {
        type: Number,
        default: 300,
    },
    defaultDuration: {
        type: Number,
        default: 1500,
    },
    ignoreDuplicates: {
        type: Boolean,
        default: false,
    },
};

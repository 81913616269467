/* eslint-disable node/no-unsupported-features/es-syntax */
import { PAYMENTS_ROUTE_NAMES } from "@/shared";

export const PAYMENTS_ROUTES = [
    {
        name: PAYMENTS_ROUTE_NAMES.LIST,
        path: "/payments",
        meta: {
            title: "Платежи",
        },
        component: () => import("../ui/PaymentsList.vue"),
    },
];

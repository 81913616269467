import { getDataMapper, mapField } from "@/shared";

export const mapDirectorPaymentsFromServer = getDataMapper({
    main: mapField("main", {
        id: mapField("id", Number),
        title: mapField("title", String),
        datePay: mapField("date_pay", String),
        directorTariff: mapField("director_tariff"),
        period: mapField("period", String),
        sum: mapField("sum", Number),
    }),
    sum: mapField("sum", Number),
    count: mapField("count", Number),
    average: mapField("average", Number),
    LTV: mapField("LTV", Number),
});

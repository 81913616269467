import { defineStore } from "pinia";
import { ref } from "vue";

import { getCookie, setToken } from "@/shared";

import { getAuthToken } from "../api/getAuthToken.js";
import { getRefreshToken } from "../api/getRefreshToken.js";
import { logoutUser } from "../api/logoutUser.js";
import { deleteAuthToken } from "../lib/deleteAuthToken.js";
import { setAuthToken } from "../lib/setAuthToken.js";

export const useAuthStore = defineStore("AuthStore", () => {
    const isAuthenticated = ref(false);
    const isAuthorized = ref(false);
    const accessToken = ref(null);
    const expires = ref(null);

    async function refreshUser() {
        const refreshData = await getRefreshToken();

        accessToken.value = refreshData.accessToken;
        expires.value = refreshData.expires;

        setAuthToken(accessToken.value, expires.value);
        setAuthenticated(true);
    }

    async function authUser(userData) {
        const authData = await getAuthToken(userData);

        accessToken.value = authData.accessToken;
        expires.value = authData.expires;

        setAuthToken(accessToken.value, expires.value);
        setAuthenticated(true);
    }

    function setAuthenticated(value) {
        isAuthenticated.value = value;

        if (value) setToken(accessToken.value ?? getCookie("jwt"));
    }

    function setAuthorized(value) {
        isAuthorized.value = value;
    }

    const $reset = function () {
        isAuthenticated.value = false;
        accessToken.value = null;
        expires.value = null;
    };

    const logout = async function (comment) {
        await logoutUser(comment);

        $reset();
        setToken(null);
        deleteAuthToken();
    };

    return {
        isAuthenticated,
        isAuthorized,
        accessToken,
        expires,
        refreshUser,

        authUser,
        logout,
        setAuthenticated,
        setAuthorized,
    };
});

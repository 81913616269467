import { getDataMapper, mapField } from "@/shared";

export const mapRightToServer = getDataMapper({
    read: mapField("canRead", Boolean),
    create: mapField("canAdd", Boolean),
    update: mapField("canEdit", Boolean),
    delete: mapField("canDelete", Boolean),
    export: mapField("canExport", Boolean),
});

export const mapRightsToServer = getDataMapper({
    ANALYTIC: mapField("ANALYTIC", mapRightToServer),
    REPORTS: mapField("REPORTS", mapRightToServer),
    RENT: mapField("RENT", mapRightToServer),
    CLIENT: mapField("CLIENT", mapRightToServer),
    INVENTORY: mapField("INVENTORY", mapRightToServer),
    CALENDAR: mapField("CALENDAR", mapRightToServer),
    EXPENSE: mapField("EXPENSE", mapRightToServer),
    STOCK: mapField("STOCK", mapRightToServer),
    REPAIR: mapField("REPAIR", mapRightToServer),
    EMPLOYEE: mapField("EMPLOYEE", mapRightToServer),
    SETTINGS: mapField("SETTINGS", mapRightToServer),
    LOG_JOURNAL: mapField("LOG_JOURNAL", mapRightToServer),
    access_to_points: mapField("hasAccessToPoints", Boolean),
});

import { getDataMapper, mapField, mapArray } from "../../../lib";
export const mapContactFromServer = getDataMapper({
    id: mapField("id", Number),
    value: mapField("value"),
    isConfirmed: mapField("confirmed", Boolean),
    contactType: mapField("contact_type", {
        id: mapField("id", Number),
        title: mapField("title"),
        const: mapField("const"),
    }),
});

export const mapContactsFromServer = mapArray(mapContactFromServer);

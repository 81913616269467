import { defineStore } from "pinia";
import { ref } from "vue";

export const useNotificationsStore = defineStore("NotificationsStore", () => {
    const notify = ref(null);

    const setNotify = (newNotify) => {
        notify.value = newNotify;
    };

    const resetNotify = () => {
        notify.value.shift();
    };

    return {
        notify,
        setNotify,
        resetNotify,
    };
});

import { getDataMapper, mapField, isArray, isNil } from "../../../lib";
export const mapContactToServer = getDataMapper({
    id: mapField("id", Number),
    value: mapField("value"),
    confirmed: mapField("isConfirmed", Boolean),
    contact_type_id: mapField("contactType", (obj) => obj.id),
});

export const mapContactsToServer = (contacts) => {
    if (!isArray(contacts)) return null;

    contacts = contacts.filter((contact) => !isNil(contact.value));

    return contacts.map((contact) => mapContactToServer(contact));
};

import { getDataMapper, mapArray, mapField } from "@/shared";

export const mapFunnelFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title", String),
    const: mapField("const"),
    color: mapField("color"),
    textColor: mapField("textColor"),
});

export const mapFunnelsFromServer = mapArray(mapFunnelFromServer);

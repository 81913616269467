import { useTitle } from "@vueuse/core";
import { computed } from "vue";
import { useRoute } from "vue-router";

export const useDynamicTitle = () => {
    const route = useRoute();

    const title = computed(() => {
        return `${import.meta.env.VITE_APP_TITLE} ${route.meta.title}`;
    });

    useTitle(title);
};

<script setup>
import { computed, onMounted, ref } from "vue";

import { format } from "../../lib/helpers";

import BaseNotificationIcon from "./BaseNotificationIcon.vue";
import importedProps from "./config/notificationProps";

const props = defineProps({ ...importedProps });
const emit = defineEmits(["close"]);

const timer = ref(null);
const start = ref(null);
const remaining = ref(null);

const formattedDate = computed(() => format(props.date, "dd.MM.yyyy"));
const formattedTime = computed(() => format(props.date, "HH:mm"));

const closeNotify = () => {
    clearTimeout(timer.value);
    emit("close", props.item);
};

const startTimer = () => {
    start.value = Date.now();
    timer.value = setTimeout(() => {
        closeNotify();
    }, props.duration);
};

const pauseTimer = () => {
    clearTimeout(timer.value);
    remaining.value = props.duration - (Date.now() - start.value);
};

const resumeTimer = () => {
    start.value = Date.now();
    clearTimeout(timer.value);
    timer.value = setTimeout(() => {
        closeNotify();
    }, remaining.value);
};

onMounted(() => {
    startTimer();
});
</script>

<template>
    <div
        class="base-notification"
        @mouseenter="pauseTimer"
        @mouseleave="resumeTimer"
    >
        <div v-if="icon" class="base-notification__icon">
            <slot name="NotificationIcon">
                <BaseNotificationIcon :value="icon" />
            </slot>
        </div>
        <div class="base-notification__content">
            <div class="base-notification__datetime">
                <time class="base-notification__time">{{ formattedTime }}</time>
                <span class="base-notification__dot"></span>
                <time class="base-notification__date">{{ formattedDate }}</time>
            </div>
            <div class="base-notification__title">
                <slot name="title">{{ title }}</slot>
            </div>
            <div class="base-notification__slot">
                <slot name="content">{{ text }}</slot>
                <slot name="link"></slot>
            </div>
        </div>
        <button class="base-notification__cross" @click="closeNotify"></button>
    </div>
</template>

<style lang="scss" scoped>
.base-notification {
    position: relative;

    display: flex;

    min-width: 100%;
    padding: var(--default-paddings);

    background-color: var(--c-background);
    border-radius: var(--default-small-border-radius);
    box-shadow: var(--default-box-shadow);

    &__title {
        margin: 1px 0 3px;

        font-size: var(--default-text-size);
    }

    &__content {
        font-size: 11px;
    }

    &__datetime {
        font-size: 10px;
    }

    &__dot {
        display: inline-block;

        width: 3px;
        height: 3px;
        margin: 0 5px 3px;

        background-color: var(--c-neutral-90);
        border-radius: 100px;
    }

    &__icon {
        align-self: flex-start;

        width: 20px;
        margin-top: 3px;
        margin-right: 15px;

        text-align: center;
    }

    &__cross {
        position: absolute;
        top: 10px;
        right: 10px;

        width: 15px;
        height: 15px;
        padding: 0;
        margin: 0;

        cursor: pointer;

        background: none !important;
        border: 0;
        opacity: 0.7;

        &:hover {
            opacity: 0.8;
        }

        &::before,
        &::after {
            position: absolute;
            top: 0;
            left: 7.5px;

            width: 2px;
            height: 15px;

            content: " ";

            background-color: var(--c-neutral-80);
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    & a {
        color: var(--c-primary);

        &:hover {
            color: var(--c-primary);
        }
    }
}
</style>
